import { useTranslation } from "react-i18next";

const Translate = ({ children }) => {
  const { t } = useTranslation();

  const translate = t(children)
  //span wrapper for google translate support
  return <span>{translate}</span>;
};

export default Translate;
