import { Box, Container, Paper } from "@mui/material";
import useGetLogo from "src/components/logo/hooks/use-logo";
import { HOST_API } from "src/config";

const AuthLayout = ({ children }) => {
  const logo = useGetLogo();

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "#F5F5F5",
      }}
    >
      <Container
        fixed
        maxWidth="md"
        sx={{
          height: "100%",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <Paper variant="outlined" sx={{ padding: 5 }}>
          <a href="/">
            <Box
              sx={{ display: "flex", mt: 12, mb: 3, justifyContent: "center" }}
            >
              <img
                style={{ width: "80px", height: "auto" }}
                src={`${HOST_API}/${logo}`}
              />
            </Box>
          </a>
          {children}
        </Paper>
      </Container>
    </Box>
  );
};

export default AuthLayout;
