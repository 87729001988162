import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import ChoosePlan from "src/components/choose-plan";
import {
  FormProvider,
  RHFCheckbox,
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import Iconify from "src/components/Iconify";
import Ternary from "src/components/ternary";
import Translate from "src/components/translate";
import { PLANS } from "src/CONSTANTS";
import useAuth from "src/hooks/useAuth";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import useQueryParams from "src/hooks/useQueryParams";
import { useAppConfig } from "src/store/app-config";
import { usePlan } from "src/store/plan";
import * as Yup from "yup";

export default function RegisterForm() {
  const { register } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { uname } = useParams();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const usernameRegex = /^[\w-]*$/;

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email("errors.register.email.email")
      .required("errors.register.email.required"),
    username: Yup.string()
      .required("errors.register.username.required")
      .matches(usernameRegex, "errors.register.username.matches"),
    password: Yup.string()
      .min(8, "errors.register.password.min")
      .required("errors.register.password.required"),
    repassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "errors.register.repassword.oneOf"
    ),
    referral: Yup.string()
      .required("Referral ID is required")
      .matches(usernameRegex, "errors.register.username.matches"),
    date_of_birth: Yup.string()
      .nullable()
      .test("required", "Date of birth is required", (v) => {
        if (v === null) return true;
        if (v !== "") return true;
      }),
  });

  const { config } = useAppConfig();
  const isDobRequired = config?.age_restriction?.status || false;

  const defaultValues = {
    email: "",
    username: "",
    password: "",
    repassword: "",
    referral: "mlmadmin",
    plan: null,
    date_of_birth: null,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    setValue,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = methods;
  useMemo(() => {
    if (isDobRequired) {
      setValue("date_of_birth", "");
    }
  }, [isDobRequired]);

  const [registrationComplete, setRegistrationComplete] = useState(false);

  const onSubmit = async (data) => {
    try {
      if (!registrationComplete) {
        await register(
          data.email,
          data.username,
          data.password,
          data.referral,
          data.plan,
          data.date_of_birth,
        );
        enqueueSnackbar("Your account has been successfully registered!", {
          variant: "success",
        });
        setRegistrationComplete(true);
      }
    } catch (error) {
      Object.entries(error.errors).forEach(([k, v]) =>
        setError(k, { message: v[0] })
      );
      setError("afterSubmit", {
        message: error.message.toString(),
      });
    }
  };

  useMemo(() => {
    if (uname) {
      methods.setValue("referral", uname);
    }
  }, [uname]);

  const plan = usePlan();

  const { queryObject } = useQueryParams();
  const { plan: selectedPlan } = queryObject;

  useEffect(() => {
    if (selectedPlan) {
      const currentPlan = PLANS[selectedPlan];
      if (currentPlan) setValue("plan", currentPlan);
      else setValue("plan", PLANS.binary);
    } else {
      setValue("plan", plan ? plan : PLANS.binary);
    }
  }, [plan, selectedPlan]);
  const showInput = watch("check");

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          rowGap: 3,
          m: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            md: "repeat(2, 1fr)",
          },
        }}
      >
        <RHFTextField name="email" label="register.email" />
        <RHFTextField name="username" label="register.username" />
        <Ternary
          when={isDobRequired}
          then={<RHFDatePicker name="date_of_birth" label="register.dob" />}
        />
        <RHFTextField
          name="password"
          label="register.password"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <RHFTextField
          name="repassword"
          label="register.re_enter_password"
          type={showRePassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={() => setShowRePassword(!showRePassword)}
                >
                  <Iconify
                    icon={showRePassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          rowGap: 3,
          m: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
          },
        }}
      >
        <RHFTextField
          name="referral"
          label={"global.referral_id"}
          disabled={Boolean(uname)}
        />
      </Box>
      <Box
        sx={{
          display: "grid",
          columnGap: 2,
          rowGap: 3,
          m: 2,
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
          },
        }}
      >
        <ChoosePlan />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Typography
          variant="body2"
          align="center"
          sx={{ color: "text.secondary", mt: 0 }}
        >
          <Checkbox type="checkbox" onChange={handleCheckboxChange} />
          <Link underline="always" color="text.primary" href="/terms">
            <Translate>register.terms</Translate>
          </Link>
          &nbsp; & &nbsp;
          <Link underline="always" color="text.primary" href="/terms">
            <Translate>register.policy</Translate>
          </Link>
        </Typography>
      </Stack>
      <Box
        sx={{
          mt: -2,
          mb: 2,
          ml: 2,
          justifyContent: "flex-start",
          display: "flex",
        }}
      >
        {showInput === false ? (
          <Typography color={"error"}>sdfhsdhn</Typography>
        ) : (
          ""
        )}
      </Box>

      <LoadingButton
        disabled={!isChecked}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        name="register"
      >
        <Translate>register.register</Translate>
      </LoadingButton>
    </FormProvider>
  );
}
