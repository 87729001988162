import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import useGetLogo from "./hooks/use-logo";
import { HOST_API } from "src/config";

const Logo = ({ disabledLink = false }) => {
  const logo = useGetLogo();
  console.log(logo);

  if (disabledLink) {
    return (
      <img
        style={{ width: "100px", height: "auto", maxHeight: "64px" }}
        src={`${HOST_API}/${logo}`}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img
        style={{ width: "100px", height: "auto" }}
        src={`${HOST_API}/${logo}`}
      />
    </RouterLink>
  );
};

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
