import { createContext, useContext, useReducer } from "react";
const defaultState = {
  tokenContract: null,
  stakingContract: null,
  signer: null,
  address: null,
};
const dataContext = createContext(defaultState);
const LOAD = "LOAD";

const reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case LOAD: {
      return payload;
    }
    default: {
      return state;
    }
  }
};
export const load = (payload) => ({ type: LOAD, payload });

const MetaMaskProvider = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, defaultState);
  return (
    <dataContext.Provider value={{ data, dispatch }}>
      {children}
    </dataContext.Provider>
  );
};

export const useMetaMask = () => useContext(dataContext);

export default MetaMaskProvider;
