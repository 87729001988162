import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const VideoTraining = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const CryptoCurrencies = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const AutomaticTrading = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const Staking = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const Stocks = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const FractionalRealEstate = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);
const NetworkMarketing = Loadable(
  lazy(() => import("src/pages/admin/subscriptionVdo/index"))
);

const SubscriptionVdo = [
  {
    path: "subscription",
    children: [
      { index: true, element: <Navigate to="video-training" /> },
      {
        path: "video-training",
        element: (
          <VideoTraining
            title="Formation en crypto-monnaies"
            category="1"
            lang="fr"
          />
        ),
      },
      {
        path: "crypto-currencies",
        element: (
          <CryptoCurrencies
            title="Signaux de crypto-monnaie"
            category="2"
            lang="fr"
          />
        ),
      },
      {
        path: "automatic-trading",
        element: (
          <AutomaticTrading
            title="Trading automatique"
            category="3"
            lang="fr"
          />
        ),
      },
      {
        path: "staking",
        element: <Staking title="Staking" category="4" lang="fr" />,
      },
      {
        path: "stocks",
        element: <Stocks title="Actions" category="5" lang="fr" />,
      },
      {
        path: "fractional-real-estate",
        element: (
          <FractionalRealEstate
            title="Immobilier fractionné"
            category="6"
            lang="fr"
          />
        ),
      },
      {
        path: "network-marketing",
        element: (
          <NetworkMarketing
            title="Marketing de réseau"
            category="7"
            lang="fr"
          />
        ),
      },
    ],
  },
];

export default SubscriptionVdo;
