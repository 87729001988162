import { lazy } from "react";
import Loadable from "src/routes/Loadable";

const ShareHolders = Loadable(
  lazy(() => import("src/pages/admin/shareHolders/index"))
);

const shareHolders = [
  {
    path: "shareHolders",
    children: [
      {
        index: true,
        element: <ShareHolders />,
      },
    ],
  },
];

export default shareHolders;
