import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const VideoTraining = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);
const CryptoCurrencies = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);
const AutomaticTrading = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);

const Staking = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);

const Stocks = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);

const FractionalRealEstate = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);
const NetworkMarketing = Loadable(
  lazy(() => import("src/pages/user/subscriptionVdo/index"))
);

const subscriptionVdoEng = {
  path: "subscriptionEng",
  children: [
    { index: true, element: <Navigate to="video-training" /> },
    {
      path: "video-training",
      element: (
        <VideoTraining title="global.vdo_training" category="1" lang="en" />
      ),
    },
    {
      path: "crypto-currencies",
      element: (
        <CryptoCurrencies
          title="global.cryptocurrencies"
          category="2"
          lang="en"
        />
      ),
    },
    {
      path: "automatic-trading",
      element: (
        <AutomaticTrading
          title="global.automatic_Trading"
          category="3"
          lang="en"
        />
      ),
    },
    {
      path: "staking",
      element: <Staking title="global.Staking" category="4" lang="en" />,
    },
    {
      path: "stocks",
      element: <Stocks title="global.Stocks" category="5" lang="en" />,
    },
    {
      path: "fractional-real-estate",
      element: (
        <FractionalRealEstate
          title="global.fractional_Real_Estate"
          category="6"
          lang="en"
        />
      ),
    },
    {
      path: "network-marketing",
      element: (
        <NetworkMarketing
          title="global.network_Marketing"
          category="7"
          lang="en"
        />
      ),
    },
  ],
};

export default subscriptionVdoEng;
