import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import axiosInstance from "src/utils/axios";
import "../mainPage/assets/css/style.css";
import Header from "./layout/header";
import RightBg from "../mainPage/assets/images/unnamed.png";
import Footer from "./layout/footer";
import android from "./assets/images/android.png";
import ios from "./assets/images/ios.png";
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./js/menu.js";
import app from "../mainPage/images/appStore.png";
import sbLogo from "../mainPage/images/sbLogo.png";
import playStore from "../mainPage/images/playStore.png";
import banner from "../mainPage/images/BannerImg.png";
import welcome from "../mainPage/images/welcomeImg.png";
import opportunuties from "../mainPage/images/opportunuties.png";
import amazone from "../mainPage/images/amazone.png";
import sam from "../mainPage/images/sam.png";
import tesla from "../mainPage/images/tesla.png";
import microsoft from "../mainPage/images/microsoft.png";
import btc from "../mainPage/images/btc.png";
import eth from "../mainPage/images/eth.png";
import doge from "../mainPage/images/doge.png";
import bnb from "../mainPage/images/bnb.png";
import network from "../mainPage/images/network.png";
import useAuth from "src/hooks/useAuth";
import LanguagePopover from "src/layouts/shared/header/language-popover";
import { useTranslation } from "react-i18next";

const MainPage = () => {
  const { uname } = useParams();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openRegister, setOpenRegister] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const handleClickOpenRegister = () => {
    setOpenRegister(true);
    handleCloseLogin(true);
  };
  const handleCloseRegister = () => {
    setOpenRegister(false);
  };

  const handleClickOpenLogin = () => {
    setOpenLogin(true);
    handleCloseRegister(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openMessage, setOpenMessage] = useState();

  const handleClickOpenMessage = () => {
    setOpenMessage(true);
  };
  const handleCloseMessage = () => {
    setOpenMessage(false);
  };

  useEffect(() => {
    if (uname) {
      handleClickOpenRegister();
    }
  }, [uname]);

  const fetchBrandSettings = async () => {
    try {
      const { data, status } = await axiosInstance("api/brand-details");
      if (status === 200) {
        const { logo, side_bar_logo, favicon } = data.data;

        if (logo) {
          localStorage.setItem("logo", logo);
          localStorage.setItem("side_bar_logo", side_bar_logo);
          localStorage.setItem("favicon", favicon);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchBrandSettings();
  }, []);
  const { user, logout, isAdmin } = useAuth();
  const isLoggedIn = !user;
  const { t } = useTranslation();
  function toggleMenu() {
    let nav = document.getElementById("navigation");
    nav.classList.toggle("navigation--visible");
  }

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="keywords" content="" />
        <meta name="author" content="" />
        <meta name="robots" content="" />
        <meta name="description" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
        <link rel="stylesheet" href="assets/css/style.css" />
        <script src="js/jquery-1.12.4.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js"></script>

        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/remixicon@4.2.0/fonts/remixicon.min.css "
          rel="stylesheet"
        />
      </Helmet>
      <body>
        <div class="bodyBox">
          <div class="container" id="navbar">
            <div class="container">
              <header class="page-header">
                <div class="page-header__top">
                  <div class="page-header__logo">
                    <img src={sbLogo} />
                  </div>
                  <div class="page-header__toggle" onClick={toggleMenu}>
                    ☰
                  </div>
                </div>
                <nav class="page-header__bottom">
                  <ul id="navigation" class="navigation">
                    <li class="navigation__item">
                      <a href="#">{t("global.home")} </a>
                    </li>
                    <li class="navigation__item">
                      <a href="#about">{t("global.about")}</a>
                    </li>
                    {/* <li class="navigation__item">
                      <a href="#">{t("global.Services")}</a>
                    </li> */}
                    <li class="navigation__item">
                      <a href="/faq">FAQ</a>
                    </li>
                    <LanguagePopover />
                    <li class="navigation__item">
                      {isLoggedIn ? (
                        <>
                          {" "}
                          <a
                            class="loginButton"
                            href={
                              isAdmin
                                ? "/admin/dashboard/business"
                                : "/user/dashboard"
                            }
                          >
                            {t("global.logIn")}
                          </a>
                        </>
                      ) : (
                        <>
                          {" "}
                          <a class="loginButton" href="/auth/login">
                            {t("global.Dashboard")}
                          </a>
                        </>
                      )}
                    </li>
                  </ul>
                </nav>
              </header>
            </div>
          </div>
          <div class="bannerBox">
            <div class="container">
              <div class="row align-items-center mdflex-column-reverse">
                <div class="col-md-5">
                  <h1 class="bannerTitle"> {t("global.futre")}</h1>
                  <p class="bannerpara">{t("global.futreText")}</p>
                  <div class="d-flex mt-3">
                    <a class="commonButton" href="/auth/register">
                      {t("global.getNow")}
                    </a>
                  </div>

                  <div class="d-flex mt-5 availableTiltle">
                    {t("global.appAvailable")}
                  </div>
                  <p class="comingSoon"> {t("global.comingSoon")}</p>

                  <div class="d-flex flex-wrap align-items-center">
                    <a class="appLink" href="">
                      <img src={app} />
                    </a>
                    <a class="appLink" href="">
                      <img src={playStore} />
                    </a>
                  </div>
                </div>
                <div class="col-md-7">
                  <img src={banner} alt="Sambusiness community" />
                </div>
              </div>
            </div>
          </div>

          <main>
            <section class="sectionpadding">
              <div id="about" class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 mt-3">
                    <div class="col-md-10">
                      <img src={welcome} alt="Sambusiness community" />
                    </div>
                  </div>

                  <div class="col-md-6 mt-3">
                    <span class="subHeading"> {t("global.Sambusiness")}</span>
                    <h2 class="mainTitle">
                      {" "}
                      {t("global.SambusinessCommunity")}
                    </h2>
                    <p class="paraColor">
                      {t("global.SambusinessCommunityText")}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="row align-items-center mdflex-column-reverse">
                  <div class="col-md-6 mt-3">
                    <span class="subHeading"> {t("global.Why")}</span>
                    <h2 class="mainTitle">{t("global.Tomorrow")}</h2>
                    <p class="paraColor">{t("global.experts")}</p>

                    <p class="paraColor">{t("global.exclusive")}</p>
                  </div>
                  <div class="col-md-6 mt-3">
                    <div class="col-md-12 d-flex justify-content-end">
                      <img src={opportunuties} alt="Sambusiness community" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="row align-items-center mdflex-column-reverse joinBox">
                  <div class="col-md-9">
                    <h3 class="joinTitle">{t("global.todayexclusive")}</h3>
                    <p class="paraColor">{t("global.journey")}</p>
                  </div>
                  <div class="col-md-3">
                    <div class="d-flex mt-3">
                      <a class="commonButton" href="auth/register">
                        {t("global.NowJoin")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="col-md-10 mx-auto">
                  <div class="row align-items-center">
                    <div class="col-md-5 mt-3">
                      <div class="col-md-10 position-relative">
                        <img src={sam} alt="Sambusiness community" />
                        <span class="subHeading ceoName">
                          <b> {t("global.Sawadogo")} </b>
                          {t("global.ceo")}
                        </span>
                      </div>
                    </div>

                    <div class="col-md-7 mt-3">
                      <span class="subHeading">
                        {" "}
                        {t("global.ceoSambusiness")}
                      </span>
                      <h2 class="mainTitle">
                        {t("global.ceoSambusinessFounder")}
                      </h2>
                      <p class="paraColor">{t("global.dedicated")}</p>

                      <p class="paraColor">
                        <b> {t("global.Sawadogo")} </b>,
                        {t("global.Sawadogoinvestment")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="col-md-9 mx-auto">
                  <div class="text-center">
                    <span class="subHeading">
                      {t("global.StocksCryptocurrency")}
                    </span>
                    <h3 class="mainTitle text-center">
                      {t("global.StocksCryptocurrencyHistory")}
                    </h3>
                    <p class="paraColor text-center">
                      {t("global.inspirationsome")}
                    </p>
                  </div>
                  <ul class="stocks nav nav-tabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-bs-toggle="tab"
                        href="#Stocks"
                      >
                        {" "}
                        {t("global.Stockss")}
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        data-bs-toggle="tab"
                        href="#Cryptocurrency"
                      >
                        {" "}
                        {t("global.Cryptocurrency")}
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content">
                    <div class="tab-pane active" id="Stocks">
                      <ul class="stockUl">
                        <li>
                          <img src={amazone} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle"> {t("global.Amazone")}</div>
                            <p class="paraColor">{t("global.Launched")}</p>
                          </div>
                        </li>

                        <li>
                          <img src={tesla} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle">{t("global.Tesla")}</div>
                            <p class="paraColor">{t("global.LaunchedTesla")}</p>
                          </div>
                        </li>

                        <li>
                          <img src={microsoft} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle">
                              {t("global.Microsoft")}
                            </div>
                            <p class="paraColor">
                              {t("global.MicrosoftMarch")}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="tab-pane fade" id="Cryptocurrency">
                      <ul class="stockUl">
                        <li>
                          <img src={btc} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle"> {t("global.BITCOIN")}</div>
                            <p class="paraColor">
                              {t("global.BITCOINNovember")}
                            </p>
                          </div>
                        </li>

                        <li>
                          <img src={eth} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle">{t("global.ETHEREUM")}</div>
                            <p class="paraColor">{t("global.ETHEREUMprice")}</p>
                          </div>
                        </li>

                        <li>
                          <img src={doge} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle">{t("global.DOGECOIN")}</div>
                            <p class="paraColor">
                              {t("global.DOGECOINLaunched")}
                            </p>
                          </div>
                        </li>

                        <li>
                          <img src={bnb} class="stockImg" />
                          <div class="stckCntnt">
                            <div class="stockTitle">{t("global.BNBCOIN")}</div>
                            <p class="paraColor">
                              {t("global.BNBCOINJanuary")}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-6 mt-3">
                    <span class="subHeading"> {t("global.MarketingPlan")}</span>
                    <h4 class="mainTitle">{t("global.Exciting")}</h4>
                    <p class="paraColor">{t("global.successshould")}</p>
                  </div>

                  <div class="col-md-6 mt-3">
                    <div class="col-md-12 d-flex justify-content-end">
                      <img src={network} alt="Sambusiness community" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <div class="row align-items-center">
                  <div class="col-md-8 mx-auto mt-3 text-center">
                    <h4 class="mainTitle">{t("global.bonuslevel")}</h4>
                    <p class="paraColor">{t("global.earnsmember")}</p>
                    <p class="paraColor">{t("global.simulation")}</p>
                  </div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">{t("global.level")}</div>
                  <div class="col-md-4">{t("global.Numberpeople")}</div>
                  <div class="col-md-5">{t("global.Earnings")}</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">1</div>
                  <div class="col-md-4">5</div>
                  <div class="col-md-5">25$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">2</div>
                  <div class="col-md-4">25</div>
                  <div class="col-md-5">50$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">3</div>
                  <div class="col-md-4">125</div>
                  <div class="col-md-5">125$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">4</div>
                  <div class="col-md-4">625</div>
                  <div class="col-md-5">625$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">5</div>
                  <div class="col-md-4">3125</div>
                  <div class="col-md-5">3125$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">6</div>
                  <div class="col-md-4">15625</div>
                  <div class="col-md-5">15625$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">7</div>
                  <div class="col-md-4">78125</div>
                  <div class="col-md-5">78125$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">8</div>
                  <div class="col-md-4">390625</div>
                  <div class="col-md-5">390625$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">9</div>
                  <div class="col-md-4">1953125</div>
                  <div class="col-md-5">1953125$</div>
                </div>

                <div class="borderBox">
                  <div class="col-md-3">10</div>
                  <div class="col-md-4">9765625</div>
                  <div class="col-md-5">9765625$</div>
                </div>

                <div class="borderBox font-20">
                  <div class="col-md-3">{t("global.Total")}</div>
                  <div class="col-md-4">12207030</div>
                  <div class="col-md-5">12207075$</div>
                </div>
                <p class="paraColor text-center mt-5">{t("global.cool")}</p>
                <p class="paraColor text-center">{t("global.coolhave")}</p>
              </div>
            </section>

            <section class="sectionpadding">
              <div class="container">
                <h5 class="mainTitle text-center">{t("global.Awards")}</h5>

                <div class="row mt-5">
                  <div class="col-md-6 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.JUNIOR")}</h6>
                      <div class="awrdFlexBox">
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Conditions")}</p>
                          <div class="awrdCntnt">
                            {t("global.Sponsorpeople")}
                          </div>
                        </div>
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Rewardd")}</p>
                          <div class="awrdCntnt">{t("global.oStar")} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.SENIOR")} </h6>
                      <div class="awrdFlexBox">
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Conditions")}</p>
                          <div class="awrdCntnt">
                            {" "}
                            {t("global.directlyhave")}
                          </div>
                        </div>
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Rewardd")}</p>
                          <div class="awrdCntnt">{t("global.Staree")} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.TEACHER")} </h6>
                      <div class="awrdFlexBox">
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Conditions")}</p>
                          <div class="awrdCntnt">{t("global.peoplehave")}</div>
                        </div>
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Rewardd")}</p>
                          <div class="awrdCntnt">{t("global.starw")} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.COACH")} </h6>
                      <div class="awrdFlexBox">
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Conditions")}</p>
                          <div class="awrdCntnt">
                            {" "}
                            {t("global.peopleJUNIOR")}
                          </div>
                        </div>
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Rewardd")}</p>
                          <div class="awrdCntnt">{t("global.starwss")} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.SUPERCOACH")} </h6>
                      <div class="awrdFlexBox">
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Conditions")}</p>
                          <div class="awrdCntnt">{t("global.peopleteam")}</div>
                        </div>
                        <div class="awrdSubBox">
                          <p class="awardTitle">{t("global.Rewardd")}</p>
                          <div class="awrdCntnt">
                            {t("global.companturnovery")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mt-4">
                    <div class="awrdBox">
                      <h6>{t("global.GeneralRisk")} </h6>
                      <div class="awrdFlexBox">
                        <div class="awrdCntnt">
                          {t("global.GeneralRiskText")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
        <footer class="footerSection">
          <div class="container">
            <img style={{ width: "80px", margin: "auto" }} src={sbLogo} />
            <p class="paraColor mt-4">
              support@sbcommunity.net <br />
              Cocody Angré, Abidjan, Côte d'ivoire
            </p>

            <ul class="footerNav">
              <li>
                <a href="/terms"> {t("global.Policy")}</a>
              </li>
              <li>
                <a href="/terms">{t("global.ServiceTerms")}</a>
              </li>
            </ul>
            <p class="mt-5 paraColor font-13"> {t("global.copuRight")}</p>
          </div>
        </footer>
      </body>
    </>
  );
};

export default MainPage;
