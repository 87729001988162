import { useEffect, useState } from "react";
import axiosInstance from "src/utils/axios";

const useFaqs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/api/faq-list");
        setData(
          data.data.map(({ question, sort_order, answer, id }) => ({
            id,
            question,
            answer,
            sort_order,
          }))
        );
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return data;
};

export default useFaqs;
